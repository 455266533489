import React from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import Navbar from './Navbar/Navbar';
import EventsPage from '../Pages/Events/EventsPage';

const App = () => {
  return (
    <>
      <Helmet titleTemplate="%s | SR On Tour" defaultTitle="SR On Tour" />

      <div id="wrapper">
        <div className="content-page">
          <div className="content">
            <Navbar />
            <main>
              <EventsPage />
            </main>
          </div>
        </div>
      </div>

      <ToastContainer
        hideProgressBar
        position="bottom-center"
      />
    </>
  );
};

export default App;
