import React from 'react';
import { Container, Nav } from 'reactstrap';

import { FlexRow } from 'Components';

const Navbar = () => {
  return (
    <Nav className="navbar-custom">
      <Container className="d-flex flex-row align-items-center justify-content-between pl-2" fluid>
        <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
          <li>
            <FlexRow alignCenter className="text-white" childSpacingX={1}>
              <i className="mdi mdi-48px mdi-account-music" />
              <span>SR On Tour</span>
            </FlexRow>
          </li>
        </ul>
        <div/>
      </Container>
    </Nav>
  );
};

export default Navbar;
