import './assets/scss/styles.scss';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App/App';
import './init';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
