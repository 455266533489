import React from 'react';
import L from 'leaflet';
import { Marker } from 'react-leaflet';
import { MarkerProps } from 'react-leaflet/types/Marker';

import { EventMarkerPopup } from 'Components';
import { Event } from 'models';

interface Props extends MarkerProps {
  event: Event;
}

const icon = L.divIcon({
  className: 'my-custom-pin',
  iconAnchor: [ 15, 24 ],
  // labelAnchor: [ -6, 0 ],
  popupAnchor: [ 0, -24 ],
  html: '<span class="artifact-marker" />',

});
const EventMarker = ({ event, ...props }: Props) => {
  return (
    <Marker icon={icon} {...props}>
      {event && <EventMarkerPopup event={event} />}
    </Marker>
  );
};

export default EventMarker;
