import React from 'react';
import { Popup } from 'react-leaflet';
import { ClickableDiv } from 'Components';
import { Event } from 'models';
import { format, parseISO } from 'date-fns';

interface Props {
  event: Event;
}

const EventMarkerPopup = ({ event, ...props }: Props) => {
  return (
    <Popup autoPan={false}>
      <ClickableDiv onClick={() => window.open(event.url, '_blank')}>
        <h5 className="child-spacing-x-1">{event.performer}</h5>
        <h5 className="child-spacing-x-1">{event.eventStartDate} ({format(parseISO(event.eventStartDate), 'EEEE')})</h5>
        <div>{event.venueName}, {event.addressLocality}, {event.addressCountry}</div>
      </ClickableDiv>
    </Popup>
  );
};

export default EventMarkerPopup;
