import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Button, ButtonGroup, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import axios from 'axios';
import { addDays, format, parseISO } from 'date-fns';
import Select from 'react-select';

import appConfig from 'appConfig';
import { Event } from 'models';
import { EventMarker, EventsMap, FlexRow, MapFlexContainer, Panel } from 'Components';
import MarkerClusterGroup from 'react-leaflet-markercluster';

const EventsPage = () => {
  const [ startRange, setStartRange ] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [ endRange, setEndRange ] = useState<string>('');
  const [ events, setEvents ] = useState<Event[]>([]);
  const [ selectedPerformer, setSelectedPerformer ] = useState<string | null>(null);

  const performers = useMemo(() => {
    return _.sortBy(_.uniq(events.map(e => e.performer)), p => p.toLowerCase());
  }, [ events ]);

  const filteredEvents = useMemo(() => {
    if(!selectedPerformer) {
      return events;
    }
    return _.filter(events, e => e.performer === selectedPerformer);
  }, [ events, selectedPerformer ]);

  useEffect(() => {
    searchEvents();
  }, [ startRange, endRange ]);

  function addDaysToRange(numDays: number) {
    if(startRange) {
      setStartRange(format(addDays(parseISO(startRange), numDays), 'yyyy-MM-dd'));
    }
    if(endRange) {
      setEndRange(format(addDays(parseISO(endRange), numDays), 'yyyy-MM-dd'));
    }
  }

  function searchEvents() {
    axios.post<Event[]>(`${appConfig.baseUrl}/api/ontour/events/search`, {
      startRange: startRange,
      endRange: endRange,
    })
      .then(({ data: events }) => {
        setEvents(events);
      });
  }

  return (
    <Container tag={Panel} className="py-2" childSpacingY={2}>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>Start Range:</Label>
            <Input type="date" value={startRange} onChange={(e) => setStartRange(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>End Range:</Label>
            <Input type="date" value={endRange} onChange={(e) => setEndRange(e.target.value)} />
          </FormGroup>
        </Col>
      </Row>
      <Row className="text-center">
        <Col md={12}>
          <FlexRow alignCenter childSpacingX={1}>
            <div>
              <ButtonGroup>
                <Button color="dark" size="sm" onClick={() => addDaysToRange(-7)}>Subtract Week</Button>
                <Button color="dark" size="sm" onClick={() => addDaysToRange(7)}>Add Week</Button>
              </ButtonGroup>
            </div>

            <div style={{ width: 300 }}>
              <Select
                value={selectedPerformer ? { value: selectedPerformer, label: selectedPerformer } : null}
                isClearable
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                options={performers.map(performer => ({ label: performer, value: performer }))}
                onChange={(option) => setSelectedPerformer(option?.value || null)}
              />
            </div>
          </FlexRow>
        </Col>
      </Row>
      <Panel>
        <MapFlexContainer>
          <EventsMap
            zoom={4}
            center={{ lat: 39.8281, lng: -98.5795 }}>
            <MarkerClusterGroup>
              {
                _.map(filteredEvents, (event) => (
                  <EventMarker key={event.id} event={event} position={{ lat: event.latitude, lng: event.longitude }} />
                ))
              }
            </MarkerClusterGroup>
          </EventsMap>
        </MapFlexContainer>
      </Panel>
    </Container>
  );
};

export default EventsPage;
